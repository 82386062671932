

import { CircularProgress, Typography, Grid, List, Paper, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Custom_Build_Score_Budget, Custom_Build_Score_Cohesion, Custom_Build_Score_Longevity, Custom_Build_Score_Performance } from "../../backend-generated-types";
import PaidIcon from '@mui/icons-material/Paid';

interface Props {
    performance?: Custom_Build_Score_Performance,
    cohesion?: Custom_Build_Score_Cohesion,
    longevity?: Custom_Build_Score_Longevity,
    budget?: Custom_Build_Score_Budget,
}

function clean_reason_desc_string(reason_description: unknown) {
    return reason_description?.toString().replaceAll('"', '') ?? '-';
}

const Build_Grade_Breakdown_Icon = ({ importance }: { importance: number }) => {
    if (importance >= 3) {
        return <ListItemIcon sx={{ color: '#39B54A', fontFamily: 'Monospace' }}>+++</ListItemIcon>;
    } else if (importance === 2) {
        return <ListItemIcon sx={{ color: '#39B54A', fontFamily: 'Monospace' }}>++</ListItemIcon>;
    } else if (importance === 1) {
        return <ListItemIcon sx={{ color: '#39B54A', fontFamily: 'Monospace' }}>+</ListItemIcon>;
    } else if (importance === 0) {
        return <ListItemIcon sx={{ fontFamily: 'Monospace' }}>-</ListItemIcon>;
    } else if (importance === -1) {
        return <ListItemIcon sx={{ color: 'red', fontFamily: 'Monospace' }}>-</ListItemIcon>;
    } else if (importance === -2) {
        return <ListItemIcon sx={{ color: 'red', fontFamily: 'Monospace' }}>--</ListItemIcon>;
    } else if (importance <= -3) {
        return <ListItemIcon sx={{ color: 'red', fontFamily: 'Monospace' }}>---</ListItemIcon>;
    } else {
        return <ListItemIcon>0</ListItemIcon>;
    }
}

const Build_Grade_Breakdown: React.FC<Props> = ({ performance, cohesion, longevity, budget }) => {

    const show_breakdown_state = performance?.on_paper_performance_breakdown || cohesion?.price_point_breakdown || longevity?.bloat_headroom_breakdown || budget?.performance_per_dollar_breakdown;

    return (
        <Paper variant={"elevation"} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h4" id={`scoring-breakdown-section`} mb={2}>
                {`Grade breakdown `}<Tooltip title={'Appraised users can see a full breakdown of their Performance, Budget, Longevity, and Cohesion scores here. Along with our reasoning you can see an impact estimation represented with up to 3 +\'s or up to 3 -\'s, designating corresponding positive or negative impact respectively.'}><IconButton><PaidIcon /></IconButton></Tooltip>
            </Typography>
            {show_breakdown_state && <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h5">Performance</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <CircularProgress color='secondary' size={30} variant={'determinate'} value={performance?.performance_score ?? 0} sx={{ m: 2 }} />
                                <Typography variant={'body1'} position='absolute'>{performance?.performance_score ?? '-'}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <List sx={{ width: '100%' }}>
                        {performance?.on_paper_performance_breakdown?.map((b, i) => {
                            return <ListItem key={i}>
                                <Build_Grade_Breakdown_Icon importance={b.importance} />
                                <ListItemText primary={b.reason} secondary={clean_reason_desc_string(b.reason_description)} />
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h5">Longevity</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <CircularProgress color='secondary' size={30} variant={'determinate'} value={longevity?.longevity_score ?? 0} sx={{ m: 2 }} />
                                <Typography variant={'body1'} position='absolute'>{longevity?.longevity_score ?? '-'}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <List>
                        {longevity?.bloat_headroom_breakdown?.map((b, i) => {
                            return <ListItem key={i}>
                                <Build_Grade_Breakdown_Icon importance={b.importance} />
                                <ListItemText primary={b.reason} secondary={clean_reason_desc_string(b.reason_description)} />
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h5">Budget</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <CircularProgress color='secondary' size={30} variant={'determinate'} value={budget?.budget_score ?? 0} sx={{ m: 2 }} />
                                <Typography variant={'body1'} position='absolute'>{budget?.budget_score ?? '-'}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <List>
                        {budget?.performance_per_dollar_breakdown?.map((b, i) => {
                            return <ListItem key={i}>
                                <Build_Grade_Breakdown_Icon importance={b.importance} />
                                <ListItemText primary={b.reason} secondary={clean_reason_desc_string(b.reason_description)} />
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h5">Cohesion (2+ parts only)</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <CircularProgress color='secondary' size={30} variant={'determinate'} value={cohesion?.cohesion_score ?? 0} sx={{ m: 2 }} />
                                <Typography variant={'body1'} position='absolute'>{cohesion?.cohesion_score ?? '-'}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <List>
                        {cohesion?.price_point_breakdown?.map((b, i) => {
                            return <ListItem key={i}>
                                <Build_Grade_Breakdown_Icon importance={b.importance} />
                                <ListItemText primary={b.reason} secondary={clean_reason_desc_string(b.reason_description)} />
                            </ListItem>
                        })}
                    </List>
                </Grid>
            </Grid>
            }
            {
                !show_breakdown_state &&
                <Typography sx={{ textAlign: 'center' }} variant="h6">Get appraised to see your build&apos;s grade breakdowns!</Typography>
            }
        </Paper >
    )
};

export default Build_Grade_Breakdown;