import { Typography, Grid, Paper, Button, IconButton, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { Custom_Build_Score_Budget, Custom_Build_Score_Cohesion, Custom_Build_Score_Longevity, Custom_Build_Score_Performance } from "../../backend-generated-types";
import Grade from "./Grade";
import InfoIcon from '@mui/icons-material/Info';

interface Props {
    performance?: Custom_Build_Score_Performance,
    cohesion?: Custom_Build_Score_Cohesion,
    longevity?: Custom_Build_Score_Longevity,
    budget?: Custom_Build_Score_Budget,
    is_regradeable?: boolean,
    regrade?: () => void,
    regrade_loading?: boolean,

}

const Build_Grades: React.FC<Props> = ({ performance, cohesion, longevity, budget, is_regradeable, regrade, regrade_loading }) => {

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [generated_date, set_generated_date] = React.useState<Date | null>(null);

    useEffect(() => {
        const date = new Date();
        set_generated_date(date);
    }, [performance, cohesion, longevity, budget]);

    // const performance_tooltip = `On paper performance: ${performance?.on_paper_performance_score ?? '-'}`;
    // const cohesion_tooltip = `Price point: ${cohesion?.price_point_score ?? '-'}`;
    // const budget_tooltip = `Performance per dollar: ${budget?.performance_per_dollar_score ?? '-'}`;
    // const longevity_tooltip = `Bloat headroom: ${longevity?.bloat_headroom_score ?? '-'}`;
    const performance_tooltip = `Our Performance grade centers around your build's on-paper performance. This holistically includes the specs of all your components.`;
    const cohesion_tooltip = `The Cohesion grade is designed to evaluate the price point of each part in your build. Specifically, we analyze each of your part's market pricing in comparison to the rest of the build.`;
    const budget_tooltip = `The Budget grade is all about the performance per dollar of your build. This is calculated by comparing the projected performance of your build to the estimated monetary value.`;
    const longevity_tooltip = `Our Longevity grade projects your build's resiliency to software bloat on a 3-year time horizon. We calculate this by looking for various benchmarks we identify as being most conducive to future-proofing.`;

    return (
        <Paper variant={"elevation"} sx={{ p: 2, mb: 2, position: 'relative' }}>
            {is_regradeable &&
                <Button
                    size={"small"}
                    aria-label="Regrade build"
                    variant="contained"
                    disabled={regrade_loading}
                    sx={{ position: 'absolute', top: 48, right: 16, width: 32, height: 32 }}
                    onClick={() => regrade ? regrade() : undefined}
                >
                    Regrade
                </Button>
            }
            <Typography variant="h4" mb={2}>
                {`Grades `}<Tooltip title={'Our grades reflect how this build should perform based on its components, usage history, and other components on the market.'}><IconButton><InfoIcon /></IconButton></Tooltip>
            </Typography>
            {
                generated_date && <Typography
                    aria-label="Grade generated date"
                    variant="body1"
                    sx={{ fontSize: '0.8rem', fontStyle: 'italic', position: 'absolute', right: 16, top: 16, width: 200, height: 32 }}
                >
                    Graded at {generated_date?.toLocaleString()}
                </Typography>
            }
            <Grid container sx={{ cursor: 'pointer' }} onClick={() => scrollToSection(`scoring-breakdown-section`)}>
                <Grid item xs={3} sx={{ minWidth: '10rem' }}>
                    <Grade type="Performance" score={performance?.performance_score} tooltip_content={performance_tooltip} />
                </Grid>
                <Grid item xs={3} sx={{ minWidth: '10rem' }}>
                    <Grade type="Longevity" score={longevity?.longevity_score} tooltip_content={longevity_tooltip} />
                </Grid>
                <Grid item xs={3} sx={{ minWidth: '10rem' }}>
                    <Grade type="Budget" score={budget?.budget_score} tooltip_content={budget_tooltip} />
                </Grid>
                <Grid item xs={3} sx={{ minWidth: '10rem' }}>
                    <Grade type="Cohesion" score={cohesion?.cohesion_score} tooltip_content={cohesion_tooltip} />
                </Grid>
            </Grid>
        </Paper >
    )
};

export default Build_Grades;