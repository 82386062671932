import React from "react";
import '../../styles/main.css';
import { Appraisal_Tag } from "../../backend-generated-types";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";

type Props = {
    appraisal_tags: Appraisal_Tag[];
}

const Appraisal_Tags = (props: Props) => {

    return (<>
        {/* <Typography variant='body1'>{`Appraisal Tags`}</Typography> */}
        {props.appraisal_tags ?
            <Stack direction="row" spacing={1}>
                {props.appraisal_tags.map((t, i) =>
                    <Tooltip title={t.description} key={i}><Chip color={'primary'}
                        variant={'outlined'} label={t.label} /></Tooltip>
                )}
            </Stack>
            : <Typography sx={{ fontStyle: 'italic' }}>No tags included on this build.</Typography>}
    </>)
}

export default Appraisal_Tags;