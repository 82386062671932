import { Card, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import * as React from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";
import { Buying_Power_Distribution } from "../../backend-generated-types";
import { useEffect, useState } from "react";

type Props = {
  title?: string;
  data?: Buying_Power_Distribution;
};

type Distribution_Data = {
  component: string;
  build_percentage: number;
};

const chart_style = {
  fontSize: 13,
};

const sample_data: Distribution_Data[] = [
  {
    component: "cpu",
    build_percentage: 30,
  },
  {
    component: "psu",
    build_percentage: 10,
  },
  {
    component: "motherboard",
    build_percentage: 20,
  },
  {
    component: "ram",
    build_percentage: 3,
  },
  {
    component: "storage",
    build_percentage: 10,
  },
  {
    component: "cpu_cooler",
    build_percentage: 2,
  },
  {
    component: "gpu",
    build_percentage: 20,
  },
  {
    component: "case",
    build_percentage: 5,
  },
  {
    component: "case_fans",
    build_percentage: 5,
  },
  {
    component: "operating_system",
    build_percentage: 5,
  },
];

function buying_power_data_to_distribution_data(
  backend_data: Buying_Power_Distribution
): Distribution_Data[] {
  return [
    {
      component: "case",
      build_percentage: backend_data.case.build,
    },
    {
      component: "case fan",
      build_percentage: backend_data.case_fan.build,
    },
    {
      component: "cpu",
      build_percentage: backend_data.cpu.build,
    },
    {
      component: "cpu cooler",
      build_percentage: backend_data.cpu_cooler.build,
    },
    {
      component: "gpu",
      build_percentage: backend_data.gpu.build,
    },
    {
      component: "motherboard",
      build_percentage: backend_data.motherboard.build,
    },
    {
      component: "operating system",
      build_percentage: backend_data.operating_system.build,
    },
    {
      component: "psu",
      build_percentage: backend_data.psu.build,
    },
    {
      component: "ram",
      build_percentage: backend_data.ram.build,
    },
    {
      component: "storage",
      build_percentage: backend_data.storage.build,
    },
  ];
}

const renderLabel = function (props: { component: string, percent: number }) {
  const { percent, component } = props
  if (percent > .02)
    return (
      `${component} ${(percent * 100).toFixed(0)}%`
    );
};

const BitLotPieChart = (props: Props) => {
  const [pieChartData, setPieChartData] = useState<
    Distribution_Data[] | undefined
  >(undefined);

  useEffect(() => {
    setPieChartData(
      props.data
        ? buying_power_data_to_distribution_data(props.data)
        : undefined
    );
  }, [props.data]);

  return (
    <Card sx={{ minWidth: 400, ...chart_style }} raised>
      {!!props.title && <CardHeader title={props.title} />}
      <CardMedia sx={{ height: 400 }}>
        <ResponsiveContainer width={"100%"} height={"80%"}>
          <PieChart width={400} height={400}>
            <Pie
              dataKey={"build_percentage"}
              isAnimationActive={false}
              data={
                pieChartData
                  ? pieChartData.filter((el) => el.build_percentage > 0)
                  : sample_data
              }
              cx="50%"
              cy="50%"
              fill="#39B54A"
              label={renderLabel}
              labelLine={false}
              nameKey="component"
            />
            <Tooltip
              formatter={(value) => {
                return `${value}%`;
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </CardMedia>
      {!!pieChartData?.filter((el) => el.build_percentage <= 2)
        .map((el) => el.component).length && (
          <CardContent>
            <Typography variant="subtitle1">{`Not labeled (< 2%):`}</Typography>
            <Typography variant="subtitle1">
              {pieChartData
                .filter((el) => el.build_percentage <= 2)
                .map((el) => el.component)
                .toLocaleString()
                .replaceAll(",", ", ")}
            </Typography>
          </CardContent>
        )}
      {!!pieChartData?.filter((el) => el.build_percentage === 0)
        .map((el) => el.component).length && (
          <CardContent>
            <Typography variant="subtitle1">{`Not pictured:`}</Typography>
            <Typography variant="subtitle1">
              {pieChartData
                .filter((el) => el.build_percentage === 0)
                .map((el) => el.component)
                .toLocaleString()
                .replaceAll(",", ", ")}
            </Typography>
          </CardContent>
        )}
    </Card >
  );
};

export default BitLotPieChart;
