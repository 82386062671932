import React from "react";
import "../styles/main.css";
import { Component } from "../backend-generated-types";
import { generate_part_page_link } from "../helpers/link_helper";
import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import { format_spec_json } from "../utils";
import { Link } from "gatsby";
import StarsIcon from '@mui/icons-material/Stars';

type Props = {
  header: string;
  component_type: string;
  components?: Component[];
  return_to_build?: string;
  return_to_build_name?: string;
  appraised_price?: string;
  tagged?: boolean;
};

const comp_selection_style = {
  maxWidth: "200px",
  marginBottom: "20px",
};

const Component_Read_Only = (props: Props) => {
  return (
    <Grid container style={comp_selection_style}>
      <Grid item xs={7}>
        {!props.tagged && <Typography variant={'h6'}>{props.header}</Typography>}
        {props.tagged && <Tooltip title="On this build, this component is particularly high-end"><Chip color={'ochre'} label={props.header} icon={<StarsIcon />} /></Tooltip>}
      </Grid>
      {props.appraised_price && <Grid item xs={4} justifyContent={'right'} sx={{ textAlign: 'right' }}>
        <Typography fontStyle={'italic'} variant={'h6'}>{props.appraised_price}</Typography>
      </Grid>}
      {!!props.components?.length &&
        props.components.map((c, i) => (
          <div key={i}>
            <Typography variant="body1" color={'primary'}>
              <Link
                style={{ color: 'inherit' }}
                to={generate_part_page_link(props.component_type, c.id, props.return_to_build, props.return_to_build_name)}
              >
                {c.base_name}
              </Link>
            </Typography>
            <>
              {format_spec_json(JSON.stringify(c.specs)).map((s, j) => { return <Typography key={i * j + j} variant="subtitle1">{s}</Typography> })}
            </>
          </div>
        ))}
    </Grid>
  );
};

export default Component_Read_Only;
