import { Card, CardHeader, CardMedia } from "@mui/material";
import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceDot,
  ResponsiveContainer,
} from "recharts";
import { Dollars_At_Time_Point } from "../../backend-generated-types";
import _ from "lodash";
import { useEffect, useState } from "react";

type Props = {
  title?: string;
  data?: Dollars_At_Time_Point[];
  reference_point?: Dollars_At_Time_Point;
};

type Line_Chart_Data_Point = {
  time: number;
  dollars?: number;
};

const sample_data: Dollars_At_Time_Point[] = [
  {
    time: new Date(
      new Date().setFullYear(new Date().getFullYear() - 2)
    ).getTime(),
    dollars: 4000,
  },
  {
    time: new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    ).getTime(),
    dollars: 3000,
  },
  {
    time: new Date().getTime(),
    dollars: 2000,
  },
  {
    time: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ).getTime(),
    dollars: 1890,
  },
  {
    time: new Date(
      new Date().setFullYear(new Date().getFullYear() + 2)
    ).getTime(),
    dollars: 1700,
  },
];

const DateFormatter = (date_string: string) => {
  return new Date(date_string).toLocaleDateString();
};
const convert_to_line_chart_data = (
  original_data: Dollars_At_Time_Point[]
): Line_Chart_Data_Point[] => {
  return original_data.map((p) => {
    return {
      time: new Date(p.time).getTime(),
      dollars: p.dollars ?? undefined,
    };
  });
};

const BitLotLineGraph = (props: Props) => {
  const [line_chart_data, set_line_chart_data] = useState<
    Line_Chart_Data_Point[] | undefined
  >(undefined);

  useEffect(() => {
    if (props.data?.length) {
      set_line_chart_data(
        convert_to_line_chart_data(_.compact([...props.data!]))
      );
    } else {
      set_line_chart_data(undefined);
    }
  }, [props.data]);
  return (
    <Card raised >
      {!!props.title && <CardHeader title={props.title} />}
      <CardMedia sx={{ height: 400 }}>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <LineChart
            data={line_chart_data ?? sample_data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              tickFormatter={DateFormatter}
              fontSize={10}
              type={"number"}
              tickCount={14}
              domain={
                line_chart_data
                  ? [
                    line_chart_data.at(0),
                    line_chart_data.at(line_chart_data.length - 1),
                  ]
                  : [
                    sample_data.at(0)!.time,
                    sample_data.at(sample_data.length - 1),
                  ]
              }
            />
            <YAxis name={"Estimated Value"} dataKey="dollars" />
            <Tooltip />
            <Legend />
            {/* '2025-03-14T19:12:00.000Z' */}
            <Line
              points={line_chart_data ?? sample_data}
              type="monotone"
              dataKey="dollars"
              stroke="#39B54A"
              activeDot={{ r: 8 }}
            />
            {!!props.reference_point && (
              <ReferenceDot
                label={{
                  value: "BitLot's appraisal",
                  position: "insideLeft",
                  dx: -10,
                  dy: 15,
                }}
                fill="#39B54A"
                stroke="#39B54A"
                alwaysShow={true}
                isFront={true}
                x={new Date(props.reference_point.time).getTime()}
                y={props.reference_point.dollars ?? 0}
                r={6}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </CardMedia>
    </Card>
  );
};

export default BitLotLineGraph;
